var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"full-wrapper"},[_c('CustomCard',{attrs:{"props":{
               width: 500,
               outlined: true,
               loading: _vm.loading ? '#F88D4C' : false,
         }}},[_c('span',{attrs:{"slot":"card-title"},slot:"card-title"},[_vm._v(_vm._s(_vm.$t('message.signUp')))]),_c('div',{attrs:{"slot":"card-body"},slot:"card-body"},[_c('v-form',{ref:"signUpForm",staticClass:"auth-form signup-form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"auth-form__body"},[_c('div',{staticClass:"form-group textfield"},[_c('v-text-field',{attrs:{"outlined":"","required":"","id":"email","rules":[
                _vm._rules.required,
                _vm._rules.email ],"placeholder":_vm.$t('message.email')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('label',{staticClass:"label",attrs:{"for":"email"}},[_vm._v(" "+_vm._s(_vm.$t('message.email'))+" "),_c('i',{staticClass:"warning-icon"},[_vm._v("!")])])],1),_c('div',{staticClass:"form-group textfield"},[_c('v-text-field',{attrs:{"outlined":"","required":"","id":"name","rules":[
                _vm._rules.required ],"placeholder":_vm.$t('message.name')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('label',{staticClass:"label",attrs:{"for":"name"}},[_vm._v(" "+_vm._s(_vm.$t('message.name'))+" "),_c('i',{staticClass:"warning-icon"},[_vm._v("!")])])],1),_c('div',{staticClass:"form-group textfield"},[_c('v-text-field',{attrs:{"outlined":"","required":"","id":"surname","rules":[
                _vm._rules.required ],"placeholder":_vm.$t('message.surname')},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}}),_c('label',{staticClass:"label",attrs:{"for":"surname"}},[_vm._v(" "+_vm._s(_vm.$t('message.surname'))+" "),_c('i',{staticClass:"warning-icon"},[_vm._v("!")])])],1),_c('div',{staticClass:"form-group textfield"},[_c('v-text-field',{attrs:{"outlined":"","id":"password","type":"password","rules":[
               _vm._rules.required,
               _vm._rules.password ],"placeholder":_vm.$t('message.password'),"required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('label',{staticClass:"label",attrs:{"for":"password"}},[_vm._v(" "+_vm._s(_vm.$t('message.password'))+" "),_c('i',{staticClass:"warning-icon"},[_vm._v("!")])])],1),_c('div',{staticClass:"form-group textfield"},[_c('v-text-field',{attrs:{"outlined":"","id":"password","type":"password","rules":[
                       _vm._rules.required,
                       _vm.customRules.confirmRule ],"placeholder":_vm.$t('message.confirmPassword'),"required":""},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('label',{staticClass:"label",attrs:{"for":"password"}},[_vm._v(" "+_vm._s(_vm.$t('message.confirmPassword'))+" "),_c('i',{staticClass:"warning-icon"},[_vm._v("!")])])],1)]),_c('div',{staticClass:"auth-form__actions signup-form__actions"},[_c('ButtonSuccess',{staticClass:"auth-form__submit",attrs:{"props":{disabled: _vm.loading},"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('message.signUp'))+" ")]),_c('router-link',{staticClass:"link",attrs:{"to":"sign-in"}},[_vm._v(" "+_vm._s(_vm.$t('message.signIn'))+" ")])],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }