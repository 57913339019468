<template>
 <div class="full-wrapper">
  <CustomCard
          :props="{
                width: 500,
                outlined: true,
                loading: loading ? '#F88D4C' : false,
          }">
   <span slot="card-title">{{$t('message.signUp')}}</span>
   <div slot="card-body">
    <v-form ref="signUpForm"
            @submit.prevent="submit"
            class="auth-form signup-form"
    >
     <div class="auth-form__body">
      <div class="form-group textfield">
        <v-text-field
                outlined
                required
                v-model="email"
                id="email"
                :rules="[
                 _rules.required,
                 _rules.email,
                ]"
                :placeholder="$t('message.email')"
        ></v-text-field>
        <label class="label" for="email">
         {{$t('message.email')}}
         <i class="warning-icon">!</i>
        </label>
      </div>
      <div class="form-group textfield">
       <v-text-field
               outlined
               required
               v-model="name"
               id="name"
               :rules="[
                 _rules.required,
                ]"
               :placeholder="$t('message.name')"
       ></v-text-field>
       <label class="label" for="name">
        {{$t('message.name')}}
        <i class="warning-icon">!</i>
       </label>
      </div>
      <div class="form-group textfield">
       <v-text-field
               outlined
               required
               v-model="surname"
               id="surname"
               :rules="[
                 _rules.required,
                ]"
               :placeholder="$t('message.surname')"
       ></v-text-field>
       <label class="label" for="surname">
        {{$t('message.surname')}}
        <i class="warning-icon">!</i>
       </label>
      </div>
      <div class="form-group textfield">
       <v-text-field
               outlined
               v-model="password"
               id="password"
               type="password"
               :rules="[
                _rules.required,
                _rules.password,
               ]"
               :placeholder="$t('message.password')"
               required
       ></v-text-field>
       <label class="label" for="password">
        {{$t('message.password')}}
        <i class="warning-icon">!</i>
       </label>
      </div>
         <div class="form-group textfield">
             <v-text-field
                     outlined
                     v-model="confirmPassword"
                     id="password"
                     type="password"
                     :rules="[
                        _rules.required,
                        customRules.confirmRule,
                     ]"
                     :placeholder="$t('message.confirmPassword')"
                     required
             ></v-text-field>
             <label class="label" for="password">
                 {{$t('message.confirmPassword')}}
                 <i class="warning-icon">!</i>
             </label>
         </div>
     </div>
     <div class="auth-form__actions signup-form__actions">
      <ButtonSuccess
              :props="{disabled: loading}"
              type="submit"
              class="auth-form__submit">
       {{$t('message.signUp')}}
      </ButtonSuccess>
      <router-link to="sign-in" class="link">
       {{$t('message.signIn')}}
      </router-link>
     </div>
    </v-form>
   </div>
  </CustomCard>
 </div>
</template>

<script>
import ButtonSuccess from '@components/Shared/Buttons/ButtonSuccess.vue';
import CustomCard from '@components/Shared/CustomCard.vue';
import rulesMixin from '@/mixins/Form/rules';

export default {
  name: 'SignUp',
  components: {
    ButtonSuccess,
    CustomCard,
  },
  mixins: [rulesMixin],
  data() {
    return {
      email: '',
      password: '',
      name: '',
      surname: '',
      confirmPassword: '',
      loading: false,
    };
  },
  computed: {
    customRules() {
      return {
        confirmRule: value => value === this.password || this.$t('message.passwordNotMatch'),
      };
    },
  },
  methods: {
    submit() {
      const {
        email, name, surname, password,
      } = this;
      if (this.formValidate(this.$refs.signUpForm)) {
        this.loading = true;
        this.$store.dispatch('auth/register', {
          email,
          name,
          surname,
          password,
        }).then(() => {
          this._showSuccessNotify(this.$t('message.registerSuccess'));
          this.$router.push('/sign-in');
        }).catch((e) => {
          const { errors } = e.response.data;
          const errorsKeys = Object.keys(errors);
          errorsKeys.forEach((key) => {
            this._showErrorNotify(errors[key][0]);
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
